import React from "react";
import { Col, Row } from "react-bootstrap";
import { CgCPlusPlus } from "react-icons/cg";
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiMongodb,
  DiPython,
  DiGit,
  DiAws,
  DiDocker,
} from "react-icons/di";
import {
  SiTensorflow,
  SiMysql,
} from "react-icons/si";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <DiPython />
        <p class="tech-note">Python</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiTensorflow />
        <p class="tech-note">Tensorflow</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiMysql />
        <p class="tech-note">MySQL</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiJavascript1 />
        <p class="tech-note">Javascript</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiMongodb />
        <p class="tech-note">MongoDB</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiReact />
        <p class="tech-note">React</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiNodejs />
        <p class="tech-note">NodeJS</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiDocker/>
        <p class="tech-note">Docker</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiAws />
        <p class="tech-note">AWS</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiGit />
        <p class="tech-note">Git</p>
      </Col>
    </Row>
  );
}
export default Techstack;
