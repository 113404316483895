import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/chatify.png";
import suicide from "../../Assets/Projects/suicide.png";
import bitsOfCode from "../../Assets/Projects/blog.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        {/* <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p> */}
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="Chatify"
              description={
                <ul>
                  <li>Personal Chat Room or Workspace to share resources and hangout with friends.</li>
                  <li>Built with React.js, Material-UI, and Firebase.</li>
                  <li>Allows users for realtime messaging, image sharing as well as supports reactions on messages.</li>
                </ul>
              }
            
              //description="Personal Chat Room or Workspace to share resources and hangout with friends build with react.js, Material-UI, and Firebase. Have features which allows user for realtime messaging, image sharing as well as supports reactions on messages."
              //ghLink="https://github.com/soumyajit4419/Chatify"
              //demoLink="https://chatify-49.web.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="InpAInt"
              description={
                <ul>
                  <li>An enhanced GAN-based model for high-resolution image inpainting.</li>
                  <li>overcomes challenges in image content reasoning and fine-grained texture synthesis.</li>
                  <li>outperforms state-of-the-art models by a significant margin, achieving promising completions in real-world applications such as logo removal, face editing, and object removal.</li>
                </ul>
              }
              //description="An enhanced GAN-based model for high-resolution image inpainting that overcomes challenges in image content reasoning and fine-grained texture synthesis. The model outperforms state-of-the-art models by a significant margin, achieving promising completions in real-world applications such as logo removal, face editing, and object removal."
              //ghLink="https://github.com/soumyajit4419/Bits-0f-C0de"
              //demoLink="https://blogs.soumya-jit.tech/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="Polyp DetAIct"
              description={
                <ul>
                  <li>Leveraged the pre-trained weights of VGG16 to develop a model with an accuracy of 93% and ROC of 0.91.</li>
                  <li>Used Tensorflow js to execute the ML model on the server side.</li>
                  <li>Used React js and Node js for developing the web application.</li>
                </ul>
              }
              //description="Leveraged the pre-trained weights of VGG16 to develop a model with an accuracy of 93% and ROC of 0.91 and used Tensorflow js to execute the ML model on the server side and used React js and Node js for developing the front-end of the web application."
              //ghLink="https://github.com/soumyajit4419/Editor.io"
              //demoLink="https://editor.soumya-jit.tech/"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title="Plant AI"
              description={
                <ul>
                  <li>Used the plant disease dataset from Kaggle and trained a image classifer model using 'PyTorch' framework.</li>
                  <li>Utilized CNN and Transfer Learning with 38 classes of various plant leaves.</li>
                  <li>The model was successfully able to detect diseased and healthy leaves of 14 unique plants with an accuracy of 98% by using Resnet34 pretrained model.</li>
                </ul>
              }
              //description="Used the plant disease dataset from Kaggle and trained a image classifer model using 'PyTorch' framework using CNN and Transfer Learning with 38 classes of various plant leaves. The model was successfully able to detect diseased and healthy leaves of 14 unique plants. I was able to achieve an accuracy of 98% by using Resnet34 pretrained model."
              //ghLink="https://github.com/soumyajit4419/Plant_AI"
              //demoLink="https://plant49-ai.herokuapp.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide}
              isBlog={false}
              title="Ai For Social Good"
              description={
                <ul>
                  <li>An AI chatbot developed Using 'Natural Launguage Processing' for the detection of suicide-related posts and user's suicide ideation in the cyberspace.</li>
                  <li>Used grid search cv to find the best parameters to train the model using Random Forest Classifier and achieved an accuracy of 96%.</li>
                  <li>Trained the model using Multilayer Bidirectional LSTM with GLOBE embedding to attain an accuracy of 97%.</li>
                </ul>
              }
              //description="Using 'Natural Launguage Processing' for the detection of suicide-related posts and user's suicide ideation in cyberspace  and thus helping in sucide prevention."
              //ghLink="https://github.com/soumyajit4419/AI_For_Social_Good"
              // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Face Recognition and Emotion Detection"
              description={
                <ul>
                  <li>An AI model to detect the emotion of an individual, developed using keras and tensorflow.</li>
                  <li>Trained a CNN classifier using 'FER-2013 dataset', achieving an accuracy of 70%.</li>
                  <li>Used Open-CV to detect the face in an image and then pass the face to the classifer to predict the emotion of a person.</li>
                </ul>
              }
              //description="Trained a CNN classifier using 'FER-2013 dataset' with Keras and tensorflow backened. The classifier sucessfully predicted the various types of emotions of human. And the highest accuracy obtained with the model was 60.1%.
              //Then used Open-CV to detect the face in an image and then pass the face to the classifer to predict the emotion of a person."
              //ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
